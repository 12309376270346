<template>
  <v-row justify="start">
    <v-dialog v-model="dialogUpdateUser" persistent width="75%">
      <v-card class="rounded-max hide_overflow" flat min-height="430px">
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="pa-3" v-if="person">
          <FormEksternal
          v-if="person.data.access_right == 'Eksternal'"
          v-bind:person="person.data"
          @pwd="changePwd"
        />
          <FormKemenkes
            v-if="person.data.access_right == 'Kemenkes'"
            v-bind:person="person.data"
            @pwd="changePwd"
          />
          <FormDinkesProv
            v-if="person.data.access_right == 'Dinkes Provinsi'"
            v-bind:person="person.data"
          />
          <FormDinkesKab
            v-if="person.data.access_right == 'Dinkes Kota'"
            v-bind:person="person.data"
          />
          <FormFasyankes
            v-if="person.data.access_right == 'Fasyankes'"
            v-bind:person="person.data"
          />
          <div>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-btn
                  text
                  small
                  class="text-capitalize small_txt color_txt"
                  @click="$emit('pwd')"
                  >Ubah Password</v-btn
                >
              </div>
            </v-col>
          </div>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import FormKemenkes from "./formUpdate/formKemenkes.vue";
import FormDinkesProv from "./formUpdate/formDinkesProv.vue";
import FormDinkesKab from "./formUpdate/formDinkesKab.vue";
import FormFasyankes from "./formUpdate/formFasyankes.vue";
import FormEksternal from "./formUpdate/formEksternal.vue";

export default {
  name: "viewUser",
  components: {
    FormKemenkes,
    FormDinkesProv,
    FormDinkesKab,
    FormFasyankes,
    FormEksternal,
  },
  props: ["dialogUpdateUser", "dataUser"],
  computed: {
    ...mapState({
      person: (state) => state.user.person,
    }),
  },
  data() {
    return {
      role: "",
      loading: false,
    };
  },
  mounted() {
    // this.getPerson();
  },
  methods: {
    getPerson(id) {
      console.log(id);
      this.loading = true;

      this.$store
        .dispatch("user/getUserByid", id)
        .then((data) => {
          console.log(data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    changePwd() {
      this.$emit("close");
      this.$emit("changePwd");
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
</style>